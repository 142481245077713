@import '~@rentacenter/racstrap/src/styles/vars';

.selectGroup,
.dateRange {
  display: flex;

  .employee {
    margin-top: $SPACE_MEDIUM;
  }

  .subReportType {
    margin: $SPACE_MEDIUM 0 0 $SPACE_MEDIUM;
  }
}

.right {
  margin-left: $SPACE_MEDIUM;
}

.formTitle {
  color: $NEUTRAL_01;
  font-weight: bold;
  font-size: pixelToRem(18);
  margin-bottom: $SPACE_MEDIUM;
}

.datePicker {
  width: pixelToRem(255);
}

.selectGroupNew {
  margin-top: $SPACE_MEDIUM;
  margin-bottom: 0;
}
