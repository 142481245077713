@import '~@rentacenter/racstrap/src/styles/vars';

.formTitle {
  color: $NEUTRAL_01;
  font-weight: bold;
  font-size: pixelToRem(18);
  margin-bottom: $SPACE_MEDIUM;
}

.formGroup {
  margin-top: 0;

  ~ .formGroup {
    margin-top: $SPACE_LARGER;
  }
}

.label {
  font-weight: bold;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: pixelToRem(45);

  .radioItemLabel {
    margin-top: 0;
  }

  .routesSelect {
    width: pixelToRem(281);
    margin-bottom: 0;
  }
}
