@import '~@rentacenter/racstrap/src/styles/vars';

.tooltip {
  background-color: $NEUTRAL_01;
  font-size: pixelToRem(12);
  line-height: pixelToRem(16);
  max-width: pixelToRem(148);
  padding: pixelToRem(9) pixelToRem(8);
  margin: 0;
  margin-top: $SPACE_SMALLER;
  border-radius: 0;
}

.arrow {
  font-size: pixelToRem(8);
  color: $NEUTRAL_01;
  right: 0;
}
