@import '~@rentacenter/racstrap/src/styles/vars';

.action {
  color: $PRIMARY_02;
  font-weight: 600;
  display: block;
  margin-bottom: $SPACE_LARGE;
  background: none;
  border: none;
  outline: none;
  text-decoration: underline;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  .downloadFile,
  .viewAsPdf,
  .viewAsCSV {
    margin-right: $SPACE_TINY;
  }

  .spinnerIcon {
    margin-right: $SPACE_TINY;
  }
}
