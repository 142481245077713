@import "~@rentacenter/racstrap/src/styles/vars";

$RACPAD-HEADER-HEIGHT: pixelToRem(101);

.mainWrapper {
  display: flex;
  flex-direction: column;

  .tabs {
    margin-top: $SPACE_LARGE;
    font-weight: 700;
  }
}
