@import '~@rentacenter/racstrap/src/styles/vars';

.reportListContainer {
  margin-top: $SPACE_LARGE;
}

.pageTitle {
  font-size: $LARGE-FONT-SIZE;
  font-weight: bold;
  line-height: pixelToRem(27);
}

.reportsList {
  display: flex;
  flex-wrap: wrap;
  padding-top: $SPACE_REGULAR;
  margin: 0 calc(#{$SPACE_REGULAR} / -2);

  .reportLink {
    text-decoration: none;
    color: $NEUTRAL_01;
  }

  .apiError {
    margin-top: pixelToRem(108);
  }
}

.reportEntry {
  margin: calc(#{$SPACE_REGULAR} / 2);
  width: pixelToRem(260);
  min-height: pixelToRem(116);

  .reportName {
    font-weight: 600;
    font-size: $LARGE-FONT-SIZE;
    line-height: pixelToRem(27);
  }

  .reportDescription {
    font-size: $NORMAL-FONT-SIZE;
    margin-top: $SPACE_TINY;
    line-height: pixelToRem(24);
  }
}
