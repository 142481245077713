@import '~@rentacenter/racstrap/src/styles/vars';

.cardTitle {
  color: $NEUTRAL_01;
  font-weight: bold;
  font-size: pixelToRem(18);
  line-height: pixelToRem(24);
  margin-bottom: $SPACE_MEDIUM;
}

.marginNegative {
  margin-left: -$SPACE_MEDIUM;
}

.marginTopNone {
  margin-top: 0;
}

.marginTopMedium {
  margin-top: $SPACE_MEDIUM;
}

.dateRange {
  display: flex;
}

.datePicker {
  width: pixelToRem(255);
}

.right {
  margin-left: $SPACE_MEDIUM;
}

.select {
  width: pixelToRem(281);
}

.selectGroup1 {
  display: flex;
  margin-bottom: 0;

  .employee {
    margin-left: $SPACE_MEDIUM;
  }
}

.addMaxWidth {
  display: block;
  max-width: pixelToRem(796);
}