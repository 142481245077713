@import '~@rentacenter/racstrap/src/styles/vars';

.wrapper {
  margin: -$SPACE_SMALL calc(#{$SPACE_LARGEST} / -2) 0;
  box-shadow: 0 pixelToRem(4) pixelToRem(7) 0 rgba(19, 37, 46, 0.08);

  .container {
    max-width: pixelToRem(500);
    margin: 0 calc(#{$SPACE_LARGEST} / 2);
    padding-bottom: $SPACE_SMALL;
    display: flex;

    label {
      flex-shrink: 0;
      margin-right: $SPACE_SMALL;
      display: flex;
      align-items: center;
    }

    .tooltipWrapper {
      flex-grow: 1;

      .select {
        margin-bottom: 0;
      }
      .selection {
        background-color: white;
      }
    }
  }
}
