@import '~@rentacenter/racstrap/src/styles/vars';

.title {
  padding: 0 0 $SPACE_LARGE 0;
  color: $NEUTRAL_01;
  font-weight: bold;
  font-size: pixelToRem(24);
  line-height: pixelToRem(36);
}

.card {
  display: inline-block;
  padding-bottom: $SPACE_SMALL;
}
