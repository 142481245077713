@import '~@rentacenter/racstrap/src/styles/vars';

.cardTitle {
  color: $NEUTRAL_01;
  font-weight: bold;
  font-size: pixelToRem(18);
  line-height: pixelToRem(24);
  margin-bottom: $SPACE_MEDIUM;
}

.filterBy{
    color: #252A31;
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 1rem;
}

.dateRange {
  display: flex;
}

.datePicker {
  width: pixelToRem(255);
}

.right {
  margin-left: $SPACE_MEDIUM;
}

.select {
  width: pixelToRem(281);
}

.selectGroupNew {
  width: 100%;
  float: left;
  margin-top: $SPACE_MEDIUM;
  margin-bottom: 0;
}


.selectGroup1 {
  width: 100%;
  float: left;
  margin-top: $SPACE_MEDIUM;
  margin-bottom: 100px;
}

.radioGroupText {
  margin-top: $SPACE_REGULAR;
}

.radioGroup {
  margin-top: $SPACE_LARGE;

  label {
    margin-top: 0;
  }
}

.radioLabel:first-child {
  margin-right: pixelToRem(330);
}

.selectGroup2 {
  display: flex;
  align-items: center;
  margin-top: $SPACE_SMALL;

  .receipt {
    margin-right: $SPACE_SMALL;
    margin-bottom: 0;
  }

  .freeTime {
    margin-bottom: 0;
  }
}
.inBetweenSelects {
  margin: 0 $SPACE_HUGE;
}

.text {
  color: $NEUTRAL_01;
  font-weight: 600;
  font-size: pixelToRem(16);
  line-height: pixelToRem(24);
  text-align: left;
}

//checkbox class
.Checkbox-module_labelRoot__tlOcy {
  width: 100%;
  float: left;
}

//text new class

.textDiv {
  
   float: left;
   width: 100%;
}

.checkbxDiv{
  margin-bottom: 15px;
}

.startDateCls{
    margin-top: 20px;
}
