@import "~@rentacenter/racstrap/src/styles/vars";

.row {
  margin-bottom: $SPACE_SMALLER;

  &:last-child {
    margin-bottom: 0;
  }
}
.filterName {
  font-weight: bold;
}
