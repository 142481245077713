@import '~@rentacenter/racstrap/src/styles/vars';

.formTitle {
  color: $NEUTRAL_01;
  font-weight: bold;
  font-size: pixelToRem(18);
  margin-bottom: $SPACE_MEDIUM;
}

.datePicker {
  width: pixelToRem(255);
}
