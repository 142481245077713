@import '~@rentacenter/racstrap/src/styles/vars';

.reportHistoryContainer {
  margin-top: $SPACE_LARGE;

  .pageTitle {
    font-size: $LARGE-FONT-SIZE;
    font-weight: bold;
    line-height: pixelToRem(27);
    margin-bottom: $SPACE_MEDIUM;
  }

  td,
  th {
    vertical-align: top;
  }

  .reportType {
    width: 30%;
  }
  .details {
    width: 28%;
  }
  .view {
    width: 10%;
  }
  .download {
    width: 12%;
  }
  .label {
    font-weight: 600;
  }

  .timeGenerated {
    width: 20%;
  }

  .empty {
    padding: $SPACE_LARGEST 0;
    text-align: center;
    color: $NEUTRAL_03;
    font-size: $BIG-FONT-SIZE;
    font-weight: bold;

    .emptyIcon {
      margin-right: $SPACE_SMALLER;
    }
  }

  .apiError {
    margin-top: $SPACE_JUMBO;
  }
}
